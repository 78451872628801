import logo from "./logo.svg";
import "./App.css";

function App() {
  const people = [
    {
      name: "SpotemGottem",
      role: "$JIT Investor",
      quote:
        "me n my niggas hold that jitcoin, yeah that jitcoin nigga that shit goin to the moon",
      imageUrl: "./images/spotem.png",
    },
    {
      name: "Foolio",
      role: "$JIT Investor",
      quote:
        "we out ere in jacksonville, we bought into this new crypto thang called jitcoin. i fw jitcoin",
      imageUrl: "./images/foolio.png",
    },
    {
      name: "Kodak Black",
      role: "Board of Jits",
      quote: "ion fw this crypto shit but im making bands of yall fuck niggas.",
      imageUrl: "./images/kodak.png",
    },
    {
      name: "Ansem",
      role: "just a jit",
      quote:
        "As a well articulated jit myself, I feel confident in purchasing this revolutionary coin. I have great ambitions for this 'jit' coin on Solana.",
      imageUrl: "./images/ansem.png",
    },
  ];

  const CA = "Bqv4TgqrKTTv9RhQnBF7dG4CPRNVWyyZQ4MCwLG5jaw9";
  const buy = "https://www.pump.fun/Bqv4TgqrKTTv9RhQnBF7dG4CPRNVWyyZQ4MCwLG5jaw9";
  const dexscreener = "";
  const telegram = "https://t.me/jitcoin_sol";
  const twitter = "https://twitter.com/jitcoin_solana";

  return (
    <>
      <p>project is dead, nobody really wanted to buy<br/>
      dm @darkrai on tg for a refund
      </p>
    </>
    // <div className="App bg-[#0B1120] min-h-screen">
    //   <div className="max-w-7xl mx-auto p-5">
    //     <div class=" text-center py-4 lg:px-4">
    //       <a href={buy} target="_blank">
    //         <div
    //           class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
    //           role="alert"
    //         >
    //           <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
    //             Alert
    //           </span>
    //           <span class="font-semibold mr-2 text-left flex-auto">
    //             Currently live on pump.fun, currently not on raydium
    //           </span>
    //           <svg
    //             class="fill-current opacity-75 h-4 w-4"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 20 20"
    //           >
    //             <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
    //           </svg>
    //         </div>
    //       </a>
    //     </div>

    //     <div className="nav flex justify-between mx-auto pt-8 text-white inter items-center">
    //       <div className="logo">
    //         <img src="./images/jitcoin.png" className="w-12 rounded-full" />
    //       </div>

    //       <div className="flex gap-10">
    //         <a href={telegram} target="_blank">
    //           Telegram
    //         </a>
    //         {/* <a href="https://google.com" target="_blank">
    //           Twitter
    //         </a> */}
    //         <a href={dexscreener} target="_blank">
    //           DexScreener
    //         </a>
    //       </div>
    //     </div>

    //     <div className="relative max-w-4xl mx-auto pt-20 sm:pt-24 lg:pt-32">
    //       <h1 className="inter-800 text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center dark:text-white">
    //         JITCOIN
    //       </h1>
    //       <p className="text-center mt-6 text-lg text-slate-600 text-center max-w-3xl mx-auto dark:text-slate-400 inter">
    //         buy this coin omm it will make you rich on foenem
    //       </p>

    //       <div className="flex mx-auto justify-center gap-4 mt-8">
    //         <a
    //           href={buy}
    //           target="_blank"
    //           className="focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 rounded-lg w-full flex items-center justify-center sm:w-auto bg-orange-500 highlight-white/20 hover:bg-orange-400"
    //         >
    //           Buy $JIT
    //         </a>
    //       </div>

    //       <img src="./images/kentrell.png" className="mx-auto w-96 mt-8" />
    //     </div>

    //     <h1 className="inter-800 text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight dark:text-white mt-16 p-8">
    //       JIT Holders
    //     </h1>
    //     <p className="text-slate-500 inter text-lg px-8 -mt-4">
    //       some of our jit holders have a few words to say
    //     </p>
    //     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-8">
    //       {people.map((person, index) => (
    //         <div
    //           key={index}
    //           className="bg-slate-800 rounded-xl p-8 text-center"
    //         >
    //           <img
    //             src={person.imageUrl}
    //             alt={person.name}
    //             className="w-24 h-24 rounded-full mx-auto"
    //           />
    //           <blockquote>
    //             <p className="mt-4 font-medium text-slate-200">
    //               "{person.quote}"
    //             </p>
    //           </blockquote>
    //           <figcaption className="font-medium mt-2">
    //             <div className="text-sky-500 dark:text-sky-400">
    //               {person.name}
    //             </div>
    //             <div className="text-slate-700 dark:text-slate-500">
    //               {person.role}
    //             </div>
    //           </figcaption>
    //         </div>
    //       ))}
    //     </div>
    //     <div className="relative max-w-5xl mx-auto pt-20 sm:pt-24 lg:pt-32">
    //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
    //         <div>
    //           <h1 className="inter-800 text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-left dark:text-white">
    //             What is a JIT?
    //           </h1>
    //           <p className="mt-6 text-lg text-slate-600 dark:text-slate-400 inter">
    //             a young muthafucka that don't know shit
    //             <br />
    //             <br />
    //             Any juvenile who doesn't know how to act right, and still needs
    //             grown-up training
    //             <br />
    //             <br />
    //             Like most these young jit's on here who say shit without really
    //             knowing what it stands for or means.
    //           </p>
    //         </div>
    //         <div className="flex justify-center lg:justify-end">
    //           <div className="relative">
    //             <img
    //               src="./images/flight.png"
    //               alt="Inspirational Figure"
    //               className="w-96 transition-transform duration-300 hover:scale-105"
    //             />
    //             <p className="absolute bottom-0 right-0 bg-slate-800 text-slate-400 text-sm p-2 rounded-tl-lg">
    //               <p>flight team stand up</p>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="grid grid-cols-3 gap-4 mt-64">
    //       {["3.png", "4.png", "8.png", "10.png", "14.png", "15.png"].map(
    //         (image, index) => (
    //           <div key={index} className="group relative">
    //             <img
    //               src={`./images/${image}`}
    //               alt={`Image ${index + 1}`}
    //               className="w-full h-full object-cover grayscale group-hover:grayscale-0 transition duration-300 ease-in-out transform group-hover:scale-110"
    //             />
    //             <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
    //               <p className="text-white text-xl">dis could be you</p>
    //             </div>
    //           </div>
    //         )
    //       )}
    //     </div>
    //     <div className="relative max-w-4xl mx-auto pt-20 sm:pt-24 lg:pt-32 pb-24">
    //       <div className="text-center">
    //         <img
    //           src="./images/jitcoin.png"
    //           alt="Jitcoin Logo"
    //           className="rounded-full mx-auto w-32 transition duration-300 ease-in-out hover:scale-110"
    //         />
    //         <h1 className="inter-800 text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight dark:text-white mt-8">
    //           JITONOMICS
    //         </h1>
    //         <p className="mt-6 text-lg text-slate-600 dark:text-slate-400 inter max-w-3xl mx-auto">
    //           this is a token ran on pump.fun, this is completely for fun and
    //           unserious, there is no presales and there is no allocations
    //         </p>
    //       </div>
    //       <div className="mt-8 bg-slate-800 dark:bg-slate-700 p-6 rounded-lg shadow-md inter">
    //         <h2 className="text-xl text-white font-bold">How It Works:</h2>
    //         <ol className="list-decimal list-inside text-white">
    //           <li>
    //             As demand increases on the bonding curve, Jitcoin's market cap
    //             aims for $69k.
    //           </li>
    //           <li>
    //             Upon hitting the target, $12k of liquidity is secured in Raydium
    //             and subsequently burned, ensuring a unique deflationary model.
    //           </li>
    //         </ol>
    //         <p className="mt-4 text-white">
    //           The future of Jitcoin's supply and liquidity is in the hands of
    //           its community. It's time to take action and contribute to the
    //           movement.
    //         </p>
    //         <div className="mt-4">
    //           <span className="block text-slate-400">Contract Address:</span>
    //           <code className="block mt-1 text-sm text-orange-400 font-bold">
    //             {CA || "Unavailable"}
    //           </code>
    //         </div>
    //         <div className="text-center mt-6">
    //           <a
    //             href={buy}
    //             className="inline-block bg-orange-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
    //           >
    //             Join the Movement
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default App;
